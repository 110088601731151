<!-- 树列表 -->
<template>
  <div id="tree">
    <div class="keyword">
      <el-input v-model="filterText" popper-class="my-autocomplete" placeholder="关键字查询">
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
    </div>
    <div class="tree-list">
      <el-tree class="filter-tree" :data="dataArr" node-key="id" :props="defaultProps" default-expand-all :filter-node-method="filterNode" ref="treeData" highlight-current :expand-on-click-node="false" @current-change="curNodeChange">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <div :style="{color:(data.type=='org'||data.online=='1' || data.online=='2'?'#415058':'#ABABAB')}">
            <i v-show="data.type!='org'" class="iconfont" :class="data.online=='1'? 'icon_online': data.online=='0'?'icon_offline':'icon_sleep'"></i>&nbsp;
            <span :style="{color:(data.alarm?'#F56C6C':'#415058')}">{{data.title?data.title:data.uid}}</span>
          </div>
        </span>
      </el-tree>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filterText: '', //关键字过滤
      treeDatas: [],
      deviceData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  methods: {
    // 过滤节点
    filterNode(value, data) {
      if (!value) return true;
      let bool =
        data.id.toString().indexOf(value) !== -1 ||
        data.id.toString().toUpperCase().indexOf(value) !== -1 ||
        data.id.toString().toLowerCase().indexOf(value) !== -1 ||
        (data.title && data.title.indexOf(value)) !== -1 ||
        (data.title && data.title.toUpperCase().indexOf(value)) !== -1 ||
        (data.title && data.title.toLowerCase().indexOf(value)) !== -1;
      // return bool;
      let label = data.id.toString() + "" + data.title;
      return label.indexOf(value) !== -1;
    },
    // 选中节点发生变化
    curNodeChange(nodeData, nodeObj) {
      // 父组件更新设备路径
      let path = this.getNodePath(nodeObj);
      this.$parent.setNodePath(path);
      // vuex更新选中节点数据
      this.$store.commit('setCurTreeData', [nodeData]);
    },
    // 节点路径
    getNodePath(node) {
      let path = "";
      if (node.level == 1) {
        return node.data.title;
      }
      path += this.getNodePath(node.parent) + " > " + (node.data.title ? node.data.title : node.data.id);
      return path;
    },
    // 找到指定节点
    findNode(id) {
      let node = this.$refs.treeData.getNode(id);
      return node;
    },
    // 设置设备（节点）在线离线状态
    setDeviceState(id, state) {
      let node = this.findNode(id);
      if (node != null) {
        node.data.state = state;
      }
    },
    getDevice(uid) {
      let obj = this.common.cFindObj(this.deviceArr, "id", uid);
      return obj;
    },
  },
  computed: {
    dataArr() {
      return this.$store.state.treeData;
    },
    deviceArr() {
      return this.$store.getters.getAllDevices;
    }
  },
  watch: {
    filterText(val) {
      this.$refs.treeData.filter(val);
    },
    // "$store.getters.getAllDevices": {
    //   handler: function (newVal, oldVal) {
    //     // 设备数据
    //     let deviceData = this.$store.getters.getAllDevices;
    //     this.deviceData = deviceData;
    //   }
    // },

  },
  mounted() {

    // this.treeDatas = [{
    // 	id: '0',
    // 	title: '全部设备',
    // 	type: 'org'
    // }];

    // this.ws.addCallback('regionsAck', (res) => {
    // 	let regions = res.regions;
    // 	if (regions.length > 0) {
    // 		regions.filter((item) => {
    // 			item.id = item.regionId;
    // 			item.parentId = item.parentRegionId;
    // 			item.type = "org";
    // 		})
    // 	}
    // 	this.treeDatas = this.treeDatas.concat(regions);
    // 	// 获取设备数据
    // 	let json = {
    // 		"cmd": "devices"
    // 	}
    // 	this.ws.send(json);
    // })

    // this.ws.addCallback('devicesAck', (res) => {
    // 	let devices = res.devices;
    // 	if (devices.length > 0) {
    // 		devices.filter((item) => {
    // 			item.id = item.uid;
    // 			item.parentId = item.regionId;
    // 			item.type = "device";
    // 			// 字段不初始化页面不更新
    // 			item.datas = [];
    // 			item.videoConfig = {};
    // 			item.gpsConfig = {};
    // 		})
    // 	}
    // 	this.treeDatas = this.treeDatas.concat(devices);
    // 	let datas = this.common.cTransTree(this.treeDatas);
    // 	//此处JSON.parse(JSON.stringify(datas))解决state数据改变页面不更新的问题
    // 	this.$store.commit('setTreeData', JSON.parse(JSON.stringify(datas)));
    // 	console.log(this.dataArr);
    // })

    // 监听设备在线离线
    this.ws.addCallback('deviceOnline', (res) => {
      let uidData = this.getDevice(res.uid);
      // console.log(333, uidData);
      // uidData.alarm = false

    })
    this.ws.addCallback('deviceOffline', (res) => {

    })
    // this.ws.addCallback('event', (res) => {
    //   let uidData = this.getDevice(res.uid);

    //   if (res.type == "sensor") {

    //     if (uidData != null) {
    //       if (uidData.model == "") {
    //         return;
    //       }
    //       uidData.alarm++;
    //     }

    //   }


    // })
  },


}
</script>

<style lang="scss">
@import "../assets/iconfont/icon_online.css"; //设备状态
#tree {
  width: 240px;
  height: 100%;
  box-sizing: border-box;
  background-color: $color_white;
  border-right: 1px solid $border_color;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .keyword {
    width: 100%;
    height: 60px;
    padding-bottom: 10px;
    box-sizing: border-box;
  }

  .tree-list {
    width: 100%;
    flex: auto;
    overflow: auto;

    // 该写法有问题，待解决
    .el-tree {
      width: 500px;
      overflow: auto;

      // 暂无数据文本
      .el-tree__empty-text {
        left: 22% !important;
      }
    }

    // 设置节点焦点样式
    // .el-tree-node:focus>.el-tree-node__content {
    // 	background-color: $color_theme;

    // 	i,
    // 	span {
    // 		color: $color_white;
    // 	}
    // }
  }
}
.icon_sleep:before {
  color: #5ab7fc;
  font-size: 12px;
}

.icon_offline:before {
  font-size: 12px;
}

.icon_online:before {
  color: #5fce48;
  font-size: 12px;
}
</style>
